import React, { Component } from 'react';
import StockChart from './Stock';
import Highcharts from 'highcharts/highstock';
import { Link, withRouter } from 'react-router-dom';
const axios = require('axios');

// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);

class BatteryVoltageGraph extends React.Component {
 
  constructor(props) {
    super(props);
    this.heading =
      props.location && props.location.state && props.location.state.data
        ? props.location.state.data
        : null;
    this.label =
      props.location && props.location.state && props.location.state.label
        ? props.location.state.label
        : null;
    this.state = {
      stockOptions: {
        chart: {
          zoomType: 'xy',
          spacingBottom: 20,
          spacingTop: 20,
          spacingLeft: 50,
          spacingRight: 150
        },
    
        time: {
          useUTC: false
        },
    
        rangeSelector: {
          labelStyle: {
            display: 'none'
          },
          buttonSpacing: 35,
          buttonTheme: {
            width: 45,
            'stroke-width': 1,
            'stroke-height': 1,
            stroke: '#e1e5eb',
            r: 2,
            fill: '#fff'
          },
          buttons: [
            {
              type: 'hour',
              count: 1,
              text: 'Min'
            },
            {
              type: 'day',
              count: 1,
              text: 'Hours'
            },
            {
              type: 'week',
              count: 1,
              text: 'Week'
            },
            {
              type: 'month',
              count: 1,
              text: 'Month'
            },
            {
              type: 'year',
              count: 1,
              text: 'Year'
            }
          ],
          inputEnabled: false,
          selected: 0
        },
    
        title: {
          text: "Battery Voltage(V)"
        },
        scrollbar: {
          barBackgroundColor: 'white',
          barBorderRadius: 0,
          barBorderWidth: 0,
          buttonBackgroundColor: 'white',
          buttonBorderWidth: 0,
          buttonArrowColor: 'white',
          buttonBorderRadius: 0,
          rifleColor: 'white',
          trackBackgroundColor: 'white',
          trackBorderWidth: 0,
          trackBorderColor: 'white',
          trackBorderRadius: 0
        },
        navigator: {
          adaptToUpdatedData: false,
          height: 0,
          xAxis: {
            labels: {
              enabled: false
            }
          },
          handles: {
            backgroundColor: 'transparent',
            borderColor: 'transparent'
          },
          outlineWidth: 0         
        },
        exporting: {
          enabled: false
        },
        yAxis: {
          max :300,
          min : 100,
          opposite: false,
          labels: {
            format: '{value}'
          }
        },
        xAxis: {
          type: 'datetime',
          events: {
            // afterSetExtremes: afterSetExtremes
          },
          dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%A',
            week: '%e. %b',
            month: "%b '%y",
            year: '%Y'
          }
        },
        labels: {
          rotation: 315
        },
    
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.Color(Highcharts.getOptions().colors[0])
                    .setOpacity(0)
                    .get('rgba')
                ]
              ]
            },
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            threshold: null
          }
        },
        series: [
          {
            name: 'Random data',
            type: 'area',
            data: []
          }
        ]
      }
    };
  }

   componentDidMount() {

       let apiCallForEnergyData = setInterval(
           async () => {
               try {
                   const responseData = await axios.get(
                       `https://9w6ur1v40h.execute-api.eu-west-1.amazonaws.com/dev/Battery?ParamType=Voltage` //API Call for every 3 minutes
                   );

                  this.setState((prevstate) => ({
                      stockOptions: { ...prevstate, series: responseData }
                   }))

                  } catch (err) {
                   console.error(':error', err);
               }

           } 
           , 300)

       this.setState({ apiCallForEnergyData })
   
    }

  componentWillUnmount() {
    //clear the interval the api call after unmounting the Component
    clearInterval(this.state.apiCallForEnergyData);
  }

  render() {
    const { stockOptions } = this.state;
    return (
      <div>
        <Link to='Dashboard'>Back</Link>
        <StockChart options={stockOptions} highcharts={Highcharts} />
      </div>
    );
  }
}

export default BatteryVoltageGraph;
import React, {useContext} from 'react';
import './EnergyFlow.css';
import { Link, withRouter } from 'react-router-dom';
import { SocketConsumer } from '../EnergyFlow/SocketManager';
import { Row,Col } from 'shards-react';
//import { comm } from "../libs/socketConfig"

var mqtt = require('mqtt')
const DATA_UPDATE_RATE_MS = 6000;

class EnergyFlow extends React.Component {

  constructor(props) {
    super(props);

    this.state ={
      BMS : [],
      Bat_01_SOC : '',
      Bat_02_SOC : '',
      Bat_03_SOC : ''
    }
  }

  //Called by React once when component initialized
  componentDidMount() {

    /*
    console.log('Coming back from battery page '+this.props.sysid);
   // API to get number of BMS's for selected system_id
    fetch(`https://9w6ur1v40h.execute-api.eu-west-1.amazonaws.com/dev/getBatteries?systemid=${this.props.sysid}`)
   //fetch(`https://9w6ur1v40h.execute-api.eu-west-1.amazonaws.com/dev/getBatteries?systemid=871b52ad-6ef4-476d-819c-42a44e39d756`)
     .then(res => res.json())
    .then(data => {
      let bms_ar=[]
      for(let i=0 ; i < data.Items.length ; i++)
      {
          bms_ar[i]=data.Items[i].Battery_ID
          console.log('Get BMS data ' +data.Items[i].Battery_ID);
      }

      this.setState({
          BMS : bms_ar
      });

    }); */
  }

  // Called by React when component will be 'deleted'
  componentWillUnmount() {
    // Stop update timer
    clearInterval(this.updateTimer);
  }

  render() {
     return (
      <div>
      <SocketConsumer>
      {({systemData,batteryData }) => {
        return (

        <div>
        {systemData.map(system => (
            <>
        <h1> IO System </h1>
        <ul className='circle-container'>
        <li>
            <Link
              to={{
                pathname: '/Load',
                state: { data: 'Load Production', type: 'load' }
              }}
              className={Math.trunc(parseInt(system.LoadPower)) == 0 ? 'disabled-link' : ''}>
              <img src={require('../../assets/images/Load_Box.png')} />
            </Link>
          </li>
          <li>
          {batteryData.map(battery => (
          <Row >
          <Link to={{
                     pathname: '/Battey',
                     state: { batid: battery.BatteryID , sysid : this.props.sysid }
                   }}>
                  {getBatteryImage(battery.SoC)}
          </Link>
          </Row>
          ))}
          </li>
          <li>
            <Link
              to={{
                pathname: '/Grid',
                state: { data: 'Grid Production', type: 'Grid' }
              }}
            //  className={Math.trunc(system.ACVoltage*system.ACCurrent).toFixed(2) == 0 ? 'disabled-link' : ''}>
            className={Math.trunc(system.MeterPower).toFixed(2) == 0 ? 'disabled-link' : ''}>
            <img src={require('../../assets/images/Grid_Box.png')} />


            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: '/Solar',
                state: { data: 'Solar Panel Production', type: 'Solar' }
              }}
              className={((system.PV1Voltage*system.PV1Current)+(system.PV2Voltage*system.PV2Current)) == 0 ? 'disabled-link' : ''}>
              <img src={require('../../assets/images/PV.png') }  />
            </Link>
          </li>
          <li>
            <img src={require('../../assets/images/IO_Converter.png')} />
          </li>
          <li className='circle-container-animation'>
            <div
              className={`arrow ${getDirectionClass(
                (system.batteryVoltageInv*system.batteryCurrentInv),
                'battery'
              )} ${getClass((system.batteryVoltageInv*system.batteryCurrentInv))}`}>
              <SpanComponent />
            </div>
            <div className={`arrow arrow2 ${getClass(((system.PV1Voltage*system.PV1Current)+(system.PV2Voltage*system.PV2Current)))}`}>
              <SpanComponent />
            </div>
            <div
              className={`arrow ${getDirectionClass(
                (system.ACVoltage*system.ACCurrent),
                'grid'
              )} ${getClass((system.ACVoltage*system.ACCurrent))}`}>
              <SpanComponent />
            </div>
            <div className={`arrow arrow4 ${getClass(system.LoadPower+~(system.BackupVoltage*system.BackupCurrent))}`}>
              <SpanComponent />
            </div>
          </li>
          <li className='circle-container-lbl'>
            <label> {Math.trunc(system.batteryVoltageInv*system.batteryCurrentInv)+'W'}</label>
            <label> {Math.trunc((system.PV1Voltage*system.PV1Current)+(system.PV2Voltage*system.PV2Current))+'W'}</label>
            <label> {Math.trunc(system.MeterPower)+'W'}</label>
            <label> {Math.trunc(system.LoadPower+~(system.BackupVoltage*system.BackupCurrent))+'W'}</label>
          </li>
        </ul>

        </>
           ))}
        </div>
        );
      }}
      </SocketConsumer>
      </div>
    );
  }
}
export default withRouter(EnergyFlow);
// Arrows colors
const getClass = data => {
  let power = parseInt(data);
  if (power == 0) {
    return 'classWhite';
  } else { //if (power > 1 && power <= 200) {
    return 'classGreen';
  } /*else if (power > 200 && power <= 400) {
    return 'classYellow';
  } else if (power > 400 && power <= 600) {
    return 'classOrange';
  } else {
    return 'classRed';
  }*/
};

const getDirectionClass = (data, name) => {
  let power = parseInt(data);
  //Arrows reverse direction when negitive values
  if (name == 'grid') {
    if (power > 0) {
      return 'arrow3-reverse';
    } else {
      return 'arrow3';
    }
  }

  if (name == 'battery') {
    if (power < 0) {
      return 'arrow1-reverse';
    } else {
      return 'arrow1';
    }
  }

  if(name == 'load'){
    if (power > 0) {
        return 'arrow4';
    } else {
    //  console.log('Display load power '+power)
      return 'arrow4';
    }
  }

};

const SpanComponent = () => (
  <React.Fragment>
    {' '}
    <span></span>
    <span></span>
    <span></span>
  </React.Fragment>
);

const getBatteryImage = (SOC = 0) => {
  return (
    <div class='batteryDiv'>
      <div class='batteryNotch'></div>
      <div class='vertical'>
        <div class='indicator' style={{ height: `calc(${SOC}%)` }}>
          <span class='batteryPercent'> {`${SOC}%`}</span>
        </div>
      </div>
    </div>
  );
};

import React, { Component } from 'react';
import StockChart from './Stock';
import Highcharts from 'highcharts/highstock';
import { Link, withRouter } from 'react-router-dom';
import  Dropdown  from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
//import moment from 'moment';

const axios = require('axios');

// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);
const DATA_UPDATE_RATE_MS = 5000;


class GridPowerGraph extends React.Component {

  constructor(props) {
    super(props);
    this.heading =
      props.location && props.location.state && props.location.state.data
        ? props.location.state.data
        : null;
    this.label =
      props.location && props.location.state && props.location.state.label
        ? props.location.state.label
        : null;

        
    this.handleChange = this.handleChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);


    this.state = {
      
      startDate: null,
      endDate : null,

      stockOptions: {
        chart: {
          zoomType: 'xy',
          spacingBottom: 10,
          spacingTop: 20,
          spacingLeft: 50,
          spacingRight: 70
        },
    
        time: {
          useUTC: false
        },
    
        rangeSelector: {
          labelStyle: {
            display: 'none'
          },
          buttonSpacing: 35,
          buttonTheme: {
            width: 45,
            'stroke-width': 1,
            'stroke-height': 1,
            stroke: '#e1e5eb',
            r: 2,
            fill: '#fff'
          },
          buttons: [
            {
              type: 'hour',
              count: 1,
              text: 'Min'
            },
            {
              type: 'day',
              count: 1,
              text: 'Hours'
            },
            {
              type: 'week',
              count: 1,
              text: 'Week'
            },
            {
              type: 'month',
              count: 1,
              text: 'Month'
            },
            {
              type: 'year',
              count: 1,
              text: 'Year'
            }
          ],
          inputEnabled: false,
          selected: 0
        },
    
        title: {
          text: ''
        },
        scrollbar: {
          barBackgroundColor: 'white',
          barBorderRadius: 0,
          barBorderWidth: 0,
          buttonBackgroundColor: 'white',
          buttonBorderWidth: 0,
          buttonArrowColor: 'white',
          buttonBorderRadius: 0,
          rifleColor: 'white',
          trackBackgroundColor: 'white',
          trackBorderWidth: 0,
          trackBorderColor: 'white',
          trackBorderRadius: 0
        },
        navigator: {
          adaptToUpdatedData: false,
          height: 0,
          xAxis: {
            labels: {
              enabled: false
            }
          },
          handles: {
            backgroundColor: 'transparent',
            borderColor: 'transparent'
          },
          outlineWidth: 0         
        },
        exporting: {
          enabled: false
        },
        yAxis: {
         // max : 30,
         // min : -1,
          opposite: false,
          labels: {
            format: '{value}'
          }
        },
        xAxis: {
          type: 'datetime',
          events: {
            // afterSetExtremes: afterSetExtremes
          },
          dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%A',
            week: '%e. %b',
            month: "%b '%y",
            year: '%Y'
          }
        },
        labels: {
          rotation: 315
        },
    
        plotOptions: {
          area: {
            fillColor: {
            /*  linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              } ,*/
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.Color(
                    Highcharts.getOptions().colors[0])
                    .setOpacity(0)
                    .get('rgba')
                ]
              ]
            },
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            threshold: null
          }
        },
        series: [
          {
            name: '',
            type: 'area',
            data: []
          }
        ]

      },
      selectValue: ""
    };
    this.handleChange = this.handleChange.bind(this);

  }

  
  handleChange(date) {
    this.setState({
        startDate: date
    })
  }
  handleEndDateChange(date){
    this.setState({
        endDate:date
    })
  }

  handleSubmit(e) {
    //e.preventDefault();
    let sDate = this.state.startDate;
  //  sDate=moment(sDate).unix()
    let eDate=this.state.endDate;
  //  eDate=moment(eDate).unix()

    // let URL='https://dgsghe6v2k.execute-api.eu-west-1.amazonaws.com/prod/plotSampleGraph?startDate=1566334512766&endDate=1566330682024'
    let URL=`https://dgsghe6v2k.execute-api.eu-west-1.amazonaws.com/prod/plotSampleGraph?startDate=${sDate}&endDate=${eDate}`
    fetch(URL)
        .then(res => res.json())
        .then(
            (result) => {
                // console.log('Deepa',JSON.stringify(result.Items))

                for(var i = 0; i < result.Items.length; i++)
                {
                    var event=new Date(parseInt(result.Items[i].btime))
                  //  xyData.push({x:event ,y: parseInt(result.Items[i].totalCurrent)});
                }

            }
        )
  }

  componentDidMount() {

    let apiCallForEnergyData = setInterval(
      async () => {
      try {
       const responseData = await axios.get(
      `https://9w6ur1v40h.execute-api.eu-west-1.amazonaws.com/dev/systemGraphBulkData?ParamType=${this.state.selectValue}` //API Call for every 3 minutes
            );
       this.setState((prevstate) => ({
       stockOptions: { ...prevstate, series: responseData }
       }))

       console.log('new selected value'+this.state.selectValue);
      } catch (err) {
       console.error(':error', err);
      }

      } 
      ,1000)

      this.setState({ apiCallForEnergyData })
}
componentWillUnmount() {
    //clear the interval the api call after unmounting the Component
    clearInterval(this.state.apiCallForEnergyData);
}

handleChange = (e) => {

   this.setState({ selectValue : e.target.value });
  }

render() {
    const { stockOptions } = this.state;

    return (
      <div>
    
        <select className="browser-default custom-select" value={this.state.selectValue}  
           onChange={this.handleChange}>
          <option>Choose your option</option>
          <option value="BatteryPower">Battery Power</option>
          <option value="LoadPower">Load Power</option>
          <option value="MeterVoltage">Meter Voltage</option>
          <option value="MeterFrequency">Meter Frequency</option>
          <option value="ConsumPower">Consum Power</option>
          <option value="PVPower">PV Power</option>
        </select>
      
      <StockChart options={stockOptions} highcharts={Highcharts} />

      
      <label>From Date: </label>
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            timeCaption="time"
                        />
      <button onClick={this.handleSubmit} className="btn btn-success">Add Date</button>
      
      </div>
    );
  }

}

export default GridPowerGraph;




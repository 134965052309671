import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import React,{useState} from 'react';


import Form from "@rjsf/material-ui";
import formData from "./formData.json"
import schema from './Variable.json'
import './styles.css'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useConfirm } from 'material-ui-confirm';
//import uiSchema from './uiSchema'

//Connection to amqp comm class
import Comms from 'commoncode'
//BatterySOC imports
import { SocketConsumer,SocketContext,SocketProvider } from '../EnergyFlow/SocketManager';
import GaugeChart from 'react-gauge-chart'
import {  Card, CardBody, Row, Col } from 'shards-react';


// Instantiating the class
 const comm = new Comms();

 const ws = comm.container.websocket_connect(WebSocket);
  // Listening to error emits
  // should not fire unless something is very wrong
 comm.on("error", (err) => {
    console.log("GOT AN ERROR", err);
 });

 comm.on("connected", async () => {
    console.log("CONNECTED");
 });

 comm.on("err", (err) => {
      console.log("ERROR", err);
 });

 comm.connect(
 {
     connection_details: ws(`wss://ws.io-comms.com:61619/amqp`, ["binary", "AMQPWSB10", "amqp"]),
     transport: 'tls',
     username: 'lifepowr',
     password: 'lifepowrAMQ2020'
 }
 )

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(5),
    minWidth: 300,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'right',
    color: theme.palette.text.secondary,
  },
}));



function App() {

const classes = useStyles();
const confirm = useConfirm();

const [state,setState] =React.useState({
  controls: false, // Settings control on/off
  controlMode : 1 // Switch to change the battery control mode : default mode set on machine is 1- normal mode
});

const chartStyle = {
  height: 250
}

function onSubmit ( data )  {

  var testCommands;
  console.log('Form data '+JSON.stringify(data.formData));
     testCommands = [ // Testing some specific examples
      { // Test case 1: An actual working  request, a task key is provided
        queue: "SYSTEM/SYSTEM_101/task",
        message: { task : "setSystemSettings", mode : data.formData }
      },
    ]


  var res = null;

   Promise.all(testCommands.map( async (val, idx) =>{ // Wait for all tests to finish
   const {queue, message, extra} = val;
   try{
     res =  comm.send(queue, message,extra); // Awaits the result of the request
     console.log(`DONE TEST ${idx + 1}`, res);
   }
   catch(err){ // Catch an error during the request lifetime
     console.log(`BAD TEST ${idx + 1}`, err);
   }

   }
   ));

   console.log("DONE TESTING");
};


function onError (errors) {
  console.log("The form has", errors.length,"errors to fix");
}

const handleChange = (event) => {
  setState({ ...state, [event.target.name]: event.target.checked });
};

const setControlMode = (e) => {
  confirm({ description: 'Battery Control Mode will be reset!' })
      .then(() => {
        setState({...state, controlMode : e.target.value})
        commFunction(state.controlMode)
        })
      .catch(() => { /* ... */ });
}

function commFunction(modeValue){

  const testCommands = [ // Testing some specific examples
    { // Test case 1: An actual working  request, a task key is provided
      queue: "SYSTEM/SYSTEM_101/task",
      message: { task : "setBatControlMode", mode : state.controlMode }
    },
  ]
  // Waiting for the 'connected' event to attempt some testing commands
 // comm.on("connected", async () => {

   var res = null;

   Promise.all(testCommands.map( async (val, idx) =>{ // Wait for all tests to finish
   const {queue, message, extra} = val;
   try{
     res =  comm.send(queue, message,extra); // Awaits the result of the request
     console.log(`DONE TEST ${idx + 1}`, res);
   }
   catch(err){ // Catch an error during the request lifetime
     console.log(`BAD TEST ${idx + 1}`, err);
   }

   }
   ));

     console.log("DONE TESTING");
    // comm.close(); // Closing after everything is done
   //});

}

function ObjectFieldTemplate(props) {
  return (

  <div className={classes.root}>
  <Grid container item xs={30} spacing={4}>
   {props.properties.map(element =>

    <div className="grid-item">

    <Grid container item xs={20} spacing={3}>
      <Paper className={classes.paper}>{element.content}</Paper>
    </Grid>
    </div>
  )}

  </Grid>

  </div>

  )
}

return (

<div className={classes.root}>
<Container maxWidth="false">

<Grid container spacing={1}>
<Grid container item xs={12} spacing={3}>
  <SocketConsumer>
      {({ systemData }) => {
        return (
          <div>
              {systemData.map(system => (
              <>
      <br></br>
      <Row>
              <h2>Frequency : {system.ACFrequency} Hz</h2>
      </Row>
      <Row>
              <h2>Power : {system.batteryVoltageInv*system.batteryCurrentInv} W</h2>
      </Row>
      </>
      ))}
      </div>
    );
    }}
  </SocketConsumer>
  </Grid>

  <Grid container item xs={10} spacing={1}>
  <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Select Battery Control Mode</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.controlMode}
          onChange={setControlMode}
          label="Select Battery Control Mode"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={0}>Inactive</MenuItem>
          <MenuItem value={1}>Normal</MenuItem>
          <MenuItem value={2}>Cycle</MenuItem>
          <MenuItem value={3}>Explore</MenuItem>
          <MenuItem value={4}>Balance</MenuItem>
        </Select>
        <FormControlLabel
        control={<Checkbox checked={state.controls} onChange={handleChange} name="controls" />}
        label="Override System Settings"
        />
        </FormControl>
  </Grid>
  <Grid container item xs={12} spacing={3}>
  {!state.controls ?
  <Form
          schema={schema}
          formData ={formData}
          ObjectFieldTemplate={ObjectFieldTemplate}
         // uiSchema={uiSchema}
          onSubmit={onSubmit}
          onError={onError}
          disabled
  /> :
  <Form
  schema={schema}
  formData ={formData}
  ObjectFieldTemplate={ObjectFieldTemplate}
 // uiSchema={uiSchema}
  onSubmit={onSubmit}
  onError={onError}

  />  }
  </Grid>

  </Grid>
  </Container>

 </div>


  );
}

export default App;


import React from 'react';
// Import Highcharts
import { Container, Row, Col } from 'reactstrap';
import moment from 'react-moment';
import 'moment-timezone';

// Load Highcharts modules
import Highcharts, { dateFormat } from 'highcharts/highstock';
import { Link, withRouter } from 'react-router-dom';
import StockChart from '../Graph/Stock';
const axios = require('axios');

// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);

class MultiSeries extends React.Component {
  constructor(props) {
    //const { state } = props.location;
    super(props);
    this.state = {
      // graphTitle: state && state.data ? state.data : null,
      // graphType: state && state.type ? state.type : null,
      seriesData: []
    };
  }

  // componentDidMount() {
  //   let newData = [];
  //   let temp = [];
  //   fetch(
  //     getAPI(this.state.graphType)
  //   )
  //     .then(response => response.json())
  //     .then(res => {
  //       // console.log(res.Items , "::res")
  //       let data = res.Items;
  //       // console.log(data, "asdfds");

  //       this.setState({
  //         seriesData: data
  //       })

  //     });
  // }

  // componentDidMount() {
  // fetch('https://c7pduqwyg6.execute-api.eu-west-1.amazonaws.com/prod/')
  //   .then(response => response.json())
  //   .then(data => {
  //     let newData = [];
  //     console.log('Response data ', data);
  // for (let i = 0; i < data.length; i++) {
  //   //X-axis data push
  //   cat.push(data[i][0]);
  //   //Y-axis data push
  //   newData.push({
  //     y: data[i][1]
  //   });
  // }
  // options.series[0].data = newData;
  // this.setState({ data: newData });
  //   });
  // for (var i = 0; i >= data.Count; i++) {
  //   newData.push(data[i].DateTime, data[i].Grid_Power);
  // }
  // }

  render() {
    const { seriesData, graphTitle } = this.state;
    let stockOptions = getData(seriesData, graphTitle);
    return (
      <div>
        <Link to='Dashboard'>Back</Link>
        <StockChart options={stockOptions} highcharts={Highcharts} />{' '}
      </div>
    );
  }
}

export default MultiSeries;

const getData = (data, graphTitle) => {
  // console.log(data, "::state");

  let tempData = [];
  if (data.length > 0) {
    data.map((item, i) => {
      if (i < 10) {
        let a = item.DateTime;
        let b = item.Grid_Power;
        tempData.push([parseInt(a), parseInt(b)]);
      }
    });
    console.log(tempData, '::data for chart');
  }

  let stockOptions = {
    chart: {
      zoomType: 'xy',
      spacingBottom: 20,
      spacingTop: 20//,
    //  spacingLeft: 100//,
     // spacingRight: 50
    },

    time: {
      useUTC: false
    },

    rangeSelector: {
      labelStyle: {
        display: 'none'
      },
      buttonSpacing: 35,
      buttonTheme: {
        width: 45,
        'stroke-width': 1,
        'stroke-height': 1,
        stroke: '#e1e5eb',
        r: 2,
        fill: '#fff'
      },
      buttons: [
        {
          type: 'hour',
          count: 1,
          text: 'Min'
        },
        {
          type: 'day',
          count: 1,
          text: 'Hours'
        },
        {
          type: 'week',
          count: 1,
          text: 'Week'
        },
        {
          type: 'month',
          count: 1,
          text: 'Month'
        },
        {
          type: 'year',
          count: 1,
          text: 'Year'
        }
      ],
      inputEnabled: false,
      selected: 0
    },

    title: {
      text: graphTitle
    },

    navigator: {
      adaptToUpdatedData: false,
      height: 0,
      xAxis: {
        labels: {
          enabled: false
        }
      },
      handles: {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      },
      outlineWidth: 0
    },
    exporting: {
      enabled: false
    },
    yAxis: {
      opposite: false
    },
    xAxis: {
      type: 'datetime',
      events: {
        // afterSetExtremes: afterSetExtremes
      },
      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%A',
        week: '%e. %b',
        month: "%b '%y",
        year: '%Y'
      }
    },
    labels: {
      rotation: 315
    },
    scrollbar: {
      barBackgroundColor: 'white',
      barBorderRadius: 0,
      barBorderWidth: 0,
      buttonBackgroundColor: 'white',
      buttonBorderWidth: 0,
      buttonArrowColor: 'white',
      buttonBorderRadius: 0,
      rifleColor: 'white',
      trackBackgroundColor: 'white',
      trackBorderWidth: 0,
      trackBorderColor: 'white',
      trackBorderRadius: 0
    },
    plotOptions: {
      area: {
        //   fillColor: {
        //       linearGradient: {
        //           x1: 0,
        //           y1: 0,
        //           x2: 0,
        //           y2: 1
        //       },
        //       stops: [
        //           [0, Highcharts.getOptions().colors[0]],
        //           [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
        //       ]
        //   },
        marker: {
          radius: 2
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null
      }
    },
    //   series: [{
    //     data: data,
    //     dataGrouping: {
    //         enabled: false
    //     }
    // }]
    series: [
      {
        name: 'Random data1',
        type: 'line',
        color: '#bf360c',
        data: (function() {
          // generate an array of random data
          var data = [],
            time = new Date().getTime(),
            i;

          for (i = -1000; i <= 0; i += 1) {
            data.push([time + i * 60000, Math.round(Math.random() * 100)]);
          }
          return data;
        })()
      },
      {
        name: 'Random data2',
        type: 'line',
        color: '#8bc34a',
        data: (function() {
          // generate an array of random data
          var data = [],
            time = new Date().getTime(),
            i;
          for (i = -1000; i <= 0; i += 1) {
            data.push([time + i * 60000 * 2, Math.round(Math.random() * 100)]);
          }
          return data;
        })()
      },
      {
        name: 'Random data3',
        type: 'line',
        color: '#80deea',
        data: (function() {
          // generate an array of random data
          var data = [],
            time = new Date().getTime(),
            i;
          for (i = -1000; i <= 0; i += 1) {
            data.push([time + i * 60000 * 4, Math.round(Math.random() * 100)]);
          }
          return data;
        })()
      }
    ]
  };

  return stockOptions;
};

//Lambda API call
const getAPI = item => {
  if (item === 'solar') {
    return 'https://c7pduqwyg6.execute-api.eu-west-1.amazonaws.com/prod/';
  } else if (item === 'battery') {
    return 'https://c7pduqwyg6.execute-api.eu-west-1.amazonaws.com/prod/';
  } else if (item === 'tower') {
    return 'https://c7pduqwyg6.execute-api.eu-west-1.amazonaws.com/prod/';
  } else if (item === 'panel') {
    return 'https://c7pduqwyg6.execute-api.eu-west-1.amazonaws.com/prod/';
  } else return 'https://c7pduqwyg6.execute-api.eu-west-1.amazonaws.com/prod/';
};

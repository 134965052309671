import React from 'react';
// Import Highcharts
import StockChart from './Stock';
import { Container, Row, Col } from 'reactstrap';
import moment from 'react-moment';
import 'moment-timezone';

// Load Highcharts modules
import Highcharts, { dateFormat, isNumber } from 'highcharts/highstock';
import { Link, withRouter } from 'react-router-dom';
const axios = require('axios');
const DATA_UPDATE_RATE_MS = 5000;

// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts);
require('highcharts/indicators/pivot-points')(Highcharts);
require('highcharts/indicators/macd')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/map')(Highcharts);

class GridCurrentGraph extends React.Component {
  constructor(props) {
    //const { state } = props.location;
    super(props);
    this.state = {
      // graphTitle: state && state.data ? state.data : null,
      // graphType: state && state.type ? state.type : null,
      seriesData: []
    };
  }

  getData = () => {
      fetch('https://9w6ur1v40h.execute-api.eu-west-1.amazonaws.com/dev/systemGraphBulkData')
      .then(res => res.json())
      .then(data => {
        let newData=[];  
        let dataSize=data.length;     
        for (var i=0;i<=dataSize-1;i++)
        {
          //newData.push({ x: (data.Items[i].TimeStamp_BPK)+j*60000, y: data.Items[i].TotalVoltage });
          newData.push({ x: data[i][0], y: data[i][2] });
        } 

        this.setState({
          seriesData: newData
        });
      });
  }
   
  componentDidMount() {
    this.updateTimer = setInterval(async () => {
    this.getData();
  }, DATA_UPDATE_RATE_MS);
    //newData=[[1580137891539,212.62],[1580137881539,212.62],[1580137871538,212.62],[1580137861536,212.62],[1580137851535,212.62],[1580137841534,212.59],[1580137831533,212.62],[1580137821531,212.47],[1580137811530,212.64],[1580137801529,212.64],[1580137791527,212.65],[1580137781526,212.67],[1580137771524,212.68],[1580137761523,212.67],[1580137751522,212.67]];
  } 


  render() {
    const { seriesData } = this.state;
    const chartConfig = getConfig(seriesData);
    //let stockOptions = getData(seriesData, graphTitle);
    return (
      <div>
        <Link to='Dashboard'>Back</Link>
        <StockChart options={chartConfig} highcharts={Highcharts} />{' '}
      </div>
    );
  }
}

export default GridCurrentGraph;

const getConfig = data => ({
    chart: {
      zoomType: 'xy',
      spacingBottom: 20,
      spacingTop: 20,
      spacingLeft: 150,
      spacingRight: 150
    },

    time: {
      useUTC: false
    },

    rangeSelector: {
      labelStyle: {
        display: 'none'
      },
      buttonSpacing: 35,
      buttonTheme: {
        width: 45,
        'stroke-width': 1,
        'stroke-height': 1,
        stroke: '#e1e5eb',
        r: 2,
        fill: '#fff'
      },
      buttons: [
        {
          type: 'hour',
          count: 1,
          text: 'Min'
        },
        {
          type: 'day',
          count: 1,
          text: 'Hours'
        },
        {
          type: 'week',
          count: 1,
          text: 'Week'
        },
        {
          type: 'month',
          count: 1,
          text: 'Month'
        },
        {
          type: 'year',
          count: 1,
          text: 'Year'
        }
      ],
      inputEnabled: false,
      selected: 0
    },

    title: {
      text: "Grid Current(A)"
    },
    scrollbar: {
      barBackgroundColor: 'white',
      barBorderRadius: 0,
      barBorderWidth: 0,
      buttonBackgroundColor: 'white',
      buttonBorderWidth: 0,
      buttonArrowColor: 'white',
      buttonBorderRadius: 0,
      rifleColor: 'white',
      trackBackgroundColor: 'white',
      trackBorderWidth: 0,
      trackBorderColor: 'white',
      trackBorderRadius: 0
    },
    navigator: {
      adaptToUpdatedData: false,
      height: 0,
      xAxis: {
        labels: {
          enabled: false
        }
      },
      handles: {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
      },
      outlineWidth: 0,
      // enabled:false,
      series: {
        data: data  
      }
    },
    exporting: {
      enabled: false
    },
    yAxis: {
      opposite: false
    },
    xAxis: {
      type: 'datetime',
      events: {
        // afterSetExtremes: afterSetExtremes
      },
      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%A',
        week: '%e. %b',
        month: "%b '%y",
        year: '%Y'
      }
    },
    labels: {
      rotation: 315
    },

    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            [
              1,
              Highcharts.Color(Highcharts.getOptions().colors[0])
                .setOpacity(0)
                .get('rgba')
            ]
          ]
        },
        marker: {
          radius: 2
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        threshold: null
      }
    },
    //   series: [{
    //     data: data,
    //     dataGrouping: {
    //         enabled: false
    //     }
    // }]
    series: [
      {
        name: 'Random data',
        type: 'area',
        data: data
           
      }
    ]
  });



import React, { createContext, useState, useEffect } from "react";
//import Comms from 'commoncode'

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {

  const [system_id, setSystem_id] = useState("");
  const [system_name, setSystem_name] = useState("");
  const [invData,setInvData] = useState("");

  /*
  useEffect(()=>{

    console.log('Check if selected coming from dashboard in context '+system_id);


    const comm = new Comms();
    const ws = comm.container.websocket_connect(WebSocket);



      comm.on("error", (err) => {
        console.log("ERROR In connecting inside context lib", err);
      });

      comm.on("connected", async () => {

        console.log("CONNECTED Inside COntexr libraryyy");

        comm.subscribe(system_id,(commObj, msg) => {

        var data=[]
        var result;

       console.log("RUNTIME SUBSCRIPTION insidee COntext library ", msg.body);

        result=JSON.parse(msg.body);
        data.push(result)

        console.log('Pushed Data display '+data);

        setInvData(data);


      });
      });

      comm.connect(
        {
          connection_details: ws(`wss://ws.io-comms.com:61619/amqp`, ["binary", "AMQPWSB10", "amqp"]),
          transport: 'tls',
          username: 'lifepowr',
          password: 'lifepowrAMQ2020'
        }
        )

  }) */


  return (
    <UserContext.Provider
      value={{
        system_id,
        system_name,
        setSystem_id,
        setSystem_name
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import React,{ createContext,useContext,useState } from "react";
import { render } from 'react-dom';
import Comms from 'commoncode' // import Amqp library
//import { UserContext  } from "../libs/contextLib";

export const SocketContext = createContext({

  systemData : {},
  batteryData : {}

});

export class SocketProvider extends React.Component {

  state = {
    systemData : [],
    batteryData : []
  }

  //Instantiating the class
  comm = new Comms();

  constructor (props) {
    super(props);

    const ws = this.comm.container.websocket_connect(WebSocket);
    // Listening to error emits
    // should not fire unless something is very wrong
    this.comm.on("error", (err) => {
       console.log("GOT AN ERROR", err);
    });

    this.comm.on("err", (err) => {
       console.log("ERROR", err);
    });

    this.comm.connect(
      {
        connection_details: ws(`wss://ws.io-comms.com:61619/amqp`, ["binary", "AMQPWSB10", "amqp"]),
        transport: 'tls',
        username: 'lifepowr',
        password: 'lifepowrAMQ2020'
      }
    )
}

//Called by React once when component initialized
componentDidMount() {

    this.comm.on("connected", async () => {

    console.log("CONNECTED Inside Socket Manager");
    console.log('Print selected system id '+this.props.sysid);

    this.comm.subscribe(this.props.sysid, (commObj, msg) => {

    var data=[]
    var result;

    console.log("RUNTIME SUBSCRIPTION in energy flow", msg.body);

    result=JSON.parse(msg.body);
    data.push(result)

    this.setState({
        systemData : data
    });

    });

    this.comm.subscribe(this.props.sysid+'/Battery',(commObj, msg) => {

        //console.log("RUNTIME SUBSCRIPTIOn for Battery Data", msg.body);
        this.setState({
          batteryData : msg.body
        })
    });

});

}

componentWillUnmount () {
    try {
        this.comm.end();
        this.comm.unsubscribe(this.props.sysid);
        this.comm.unsubscribe(this.props.sysid+'/Battery');

    } catch (e) {
      // socket not connected
    }
 }


  render () {
    return (
      <SocketContext.Provider value={{
          systemData : this.state.systemData ,batteryData : this.state.batteryData
      }}>
        {this.props.children}
      </SocketContext.Provider>
    );
  }

}

export const SocketConsumer = SocketContext.Consumer;


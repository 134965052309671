import React from 'react'
import $ from 'jquery'
// Load Highcharts modules
import Highcharts, { dateFormat } from 'highcharts/highstock';
import { Link, withRouter } from 'react-router-dom';
import StockChart from '../Graph/Stock';
import HighchartsReact from 'highcharts-react-official';

/**
 * A Chart button: tap the button to increase the count.
*/
var seriesOptions = [],
seriesCounter=0,

//names = ["Current", "Voltage", "Power","SoC","ChargeMos","DischargeMoS","FailureInfo","ErrorMessage",
  //      "HighCellVoltages","LowCellVoltages"];

names = ["Current", "Voltage"]//,"ChargeMos","DischargeMoS","FailureInfo","ErrorMessage"];

var Chart;

function createChart (labels, data)  {
  var options=  {
    chart: {
      renderTo: 'container2',
      allowChartUpdate : true
    },/*
    rangeSelector: {
        selected: 4
    }, */
    time: {
      useUTC: false
    },
      plotOptions: {
        series: {
          compare: "percent",
          showInNavigator: true
        }
      },
      /*
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
        valueDecimals: 2,
        split: true
      }, */
      series: data
    };
    Chart=new Highcharts.stockChart(options);
}; 

class BMS2 extends React.Component {

  constructor(props){
    super(props);
    
    this.state ={
    
      selectValue : "" ,
      selectBattery : "",
      selectDisplay : "Current",
      seriesCheck : false
    }
  //  this.handleChange = this.handleChange.bind(this);
    
  }
  /*
  componentDidMount()
  {
    const self=this
    $.getJSON(`https://9w6ur1v40h.execute-api.eu-west-1.amazonaws.com/dev/getBMSChartdata?systemid=SYSTEM_101&batteryid=Bat_01`,   
      function (jsonx) {

      $.each(jsonx.datasets, function (i, dataset) {    
        
      // Add X values
      dataset.data = Highcharts.map(dataset.data, function (val, j) {
        return [jsonx.xData[j], val]
      })

      if(dataset.name==self.state.selectDisplay)
      {
       // console.log('selected display value '+ self.state.selectDisplay);
        seriesOptions[0] = {
        data: dataset.data,
        name: dataset.name,
        type: dataset.type
        };

        Chart = this;

         
        if(!Chart)
        {
          createChart('a',seriesOptions)
        }   
        else { 
          Chart.destroy()
          createChart('a',seriesOptions)
        } 

      }    
      
      })
      
      })  
  
  } */
  
  handleChange = (e) => {
    const {value} = e.target.value;
    const self=this
    
    this.setState({
        selectDisplay : e.target.value
    });

    seriesCounter =0;
  
    $.getJSON(`https://9w6ur1v40h.execute-api.eu-west-1.amazonaws.com/dev/getBMSChartdata?systemid=SYSTEM_101&batteryid=Bat_01`,   
      function (jsonx) {


      $.each(jsonx.datasets, function (i, dataset) {    
        
      // Add X values
      dataset.data = Highcharts.map(dataset.data, function (val, j) {
        return [jsonx.xData[j], val]
      })
    
      if(dataset.name==self.state.selectDisplay)
      {
        
        console.log('selected display value '+ self.state.selectDisplay);
        seriesOptions[0] = {
        data: dataset.data,
        name: dataset.name,
        type: dataset.type
        };

        if(!Chart)
        {
          createChart('a',seriesOptions)
        }   
        else { 
          Chart.destroy()
          createChart('a',seriesOptions)
        }

      }
    

    /*  seriesCounter += 1;

      if(seriesCounter==names.length)
      {
        if(!Chart)
        {
          createChart('a',seriesOptions)
        }   
        else { 
          Chart.destroy()
          createChart('a',seriesOptions)
        }

      } */ 
      
      })
      
      })    
  }
  
  render () {
    return (
      <div>
      <h6>BMS 05 </h6>
      <Link to='Dashboard'>Back</Link>
      <select className="browser-default custom-select" value={this.state.selectDisplay}  
           onChange={this.handleChange}>
          <option>Choose your option</option>
          <option value="Current">Current</option>
          <option value="Voltage">Voltage</option>
          <option value="Power">Power</option>
          <option value="HighCellVoltages">High Cell Voltages</option>
          <option value="LowCellVoltages">Low Cell Voltages</option>
          <option value="SoC">SoC</option>
          <option value="ChargeMoS">Charge MoS</option>
          <option value="DischargeMoS">Discharge MoS</option>
          <option value="FailureInfo">Failure Info</option>
          <option value="ErrorMessage">Error Message</option>
            
          <option value="MinTemp">Minimun Temperature</option>
          
          <option value="MaxTemp">Maximum Temperature</option>
      </select>

      <div id="container2"> </div>
      
      </div>
      
     )
  }
}
export default BMS2

/* 

 yAxis: [{ // Primary yAxis
      labels: {
          format: '{value} A',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      title: {
          text: 'Current',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      }
  }, { // Secondary yAxis
      title: {
          text: 'Voltage',
          style: {
              color: Highcharts.getOptions().colors[1]
          }
      },
   //   max : 300,
   //   min : 0,
      labels: {
          format: '{value} V',
          style: {
              color: Highcharts.getOptions().colors[1]
          }
      },
      opposite: true
    }, { // Tertiary yAxis
    gridLineWidth: 0,
    title: {
        text: 'Power',
        style: {
            color: Highcharts.getOptions().colors[2]
        }
    },
    min : -2500,
    max : 2500,
    labels: {
        format: '{value} W',
        style: {
            color: Highcharts.getOptions().colors[2]
        }
    },
    opposite: true
  }]
  */
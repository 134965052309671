import React,{useContext,useEffect} from 'react';
import { Container, Card, CardBody, Row, Col } from 'shards-react';
import { Link, withRouter } from 'react-router-dom';
import EnergyFlow from '../components/EnergyFlow/EnergyFlow';
import SystemSettings from '../components/EnergyFlow/SystemSettings'
import { SocketProvider } from '../components/EnergyFlow/SocketManager'
import { ConfirmProvider } from 'material-ui-confirm';
import { UserContext, UserProvider  } from "../libs/contextLib";

const Dashboard = (props) => {

  const System = useContext(UserContext);

  /*
  useEffect(()=>{

    console.log('Print system Id selected from System Dashboard '+System.system_id);
    System.setSystem_id(System.system_id)

  },[]) */

  return (
  <div>
  <ConfirmProvider>

  <SocketProvider sysid={System.system_id} sysname={System.system_name}>

  <Container fluid className='main-content-container py-4'>
  <Link to='SystemDashboard'>Back</Link>
    <Row>
    <Col lg='6' md='5' sm='12' className='mb-4'>
    <Card small className='h-100'>
    <CardBody className='pt-0'>
          <SystemSettings />
          </CardBody>
    </Card>
    </Col>
    <Col lg='6' md='10' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
          <EnergyFlow sysid={System.system_id} sysname={System.system_name}/>
          </CardBody>
        </Card>
    </Col>
    </Row>

    </Container>
    </SocketProvider>

    </ConfirmProvider>
    </div>
  )
}

export default Dashboard;


/*
  <BatterySoC sysid={props.location.state.systemid} sysname={props.location.state.systemname}/>

 <SocketProvider sysid={'SYSTEM_101'} sysname={'SYSTEM_101'}>

      <AppContext.Consumer>
           {({ selectedSystem }) => {
            <span>{selectedSystem} </span>

           }}
         </AppContext.Consumer>
 */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Nav,
  Navbar,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from 'shards-react';

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions = () => {
    this.setState({
      visible: !this.state.visible
    });
  };
  removeLocalstorage = () => {
    const token = localStorage.getItem('user_id');
    const {host, pathname, protocol} = window.location;
    const url = `https://io-monitor.auth.eu-west-1.amazoncognito.com/logout?client_id=30nnl80kfur8ips1ppajnkrkrp&logout_uri=${protocol}//${host}`;

    localStorage.removeItem('user_id');
    localStorage.removeItem('user_name');

    window.location.href = url;

  };
  render() {
    return (
      <Nav navbar className='flex-row'>
        <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
          <DropdownToggle caret tag={NavLink} className='text-nowrap px-3'>
            <img
              className='user-avatar rounded-circle mr-2'
              src={require('../../../assets/images/user.png')}
              alt='User Avatar'
              width={50}
              height={40}
            />{' '}
            <span className='d-none d-md-inline-block'>
              {localStorage.getItem('user_name')
                ? JSON.parse(localStorage.getItem('user_name'))
                : ''}
            </span>
          </DropdownToggle>
          <Collapse tag={DropdownMenu} right small open={this.state.visible}>
            <DropdownItem
              tag={Link}
              to='/'
              className='text-danger'
              onClick={this.removeLocalstorage}>
              <i className='material-icons text-danger'>&#xE879;</i> Logout
            </DropdownItem>
          </Collapse>
        </NavItem>
      </Nav>
    );
  }
}

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export default function withAuth(ComponentToProtect) {
    return class extends Component {
        constructor() {
            super();
            this.state = {
                loading: true,
                redirect: false,
                token: null
            };
        }

        componentDidMount() {
            const splitted1 = window.location.href.split("#");
            const splitted2 = splitted1[1] ? splitted1[1].split("&") : null;
            const queryString = {};

            if(splitted2){

                const splitted3 = splitted2[0] ? splitted2[0].split("=") : null;

                splitted2.reduce( (acc, val) =>{
                    const [k, v] = val.split("=");

                    if(k && v){
                        acc[k] = v;
                    }

                    return acc;
                }, queryString);

                if(splitted3){

                  const token = splitted3[1];

                  localStorage.setItem(
                    'user_id',
                     token
                  );
                  /*store token here in LOCALSTORAGE*/
                }
            }

            const { state } = queryString;
            const tokenStore = localStorage.getItem('user_id');

            if (!tokenStore) {
                this.setState({ loading: false, redirect: true, state });
            }
            else {
                this.setState({ loading: false, token: tokenStore, state});
            }
        }


        render() {

            const { loading, redirect, token, state } = this.state;
            const {host, pathname, protocol} = window.location;
            const redirect_uri = `https://io-monitor.auth.eu-west-1.amazoncognito.com/login?client_id=30nnl80kfur8ips1ppajnkrkrp&response_type=token&state=${pathname}&scope=email+openid&redirect_uri=${protocol}//${host}`

            if (loading) {
                return null;
            }
            if (redirect) {
                window.location.href = redirect_uri //<Redirect to="/login" />;
            }

            if(token){
                console.log(pathname, state);
                window.history.replaceState({}, document.title, `${pathname}`);
                if(state && pathname !== state){
                    return (<Redirect to={state} />)
                }
            }

            return (
                <React.Fragment>
                    <ComponentToProtect {...this.props} />
                </React.Fragment>
            );
        }
    }
}
const EventEmitter = require('events');
const amqp = require('rhea');
const {nanoid} = require('nanoid');

/**
 * CORE COMMUNICATION CLASS
 * Description: Global class to be used to ensure communications through AMQP
 * 
 */
class Comms extends EventEmitter {
    /**
     * @param  {Object} connDetails connection details for [rhea]{@link https://github.com/amqp/rhea#connectoptions}, 
     * @param  {Object} tasks Object containing tasks, each task is an asynchronous function with two arguments: 1. the instance of the communication class; 2. the body of the message of the task to preform
     * @param  {str} listener String defining the listening (or listening prefix) for queues
     * @param  {Object} extra Optional object to customize instance, 'id': A prefix for all listening queues for multiple instances using the same tasks
     */
    constructor(tasks, listener, extra = {}){
        const { id, topics = {} } = extra;

        super();
        this.tasks = tasks; // Local storing tasks
        this.listener = id ? `${listener}/${id}` : listener; // Customizing queue prefix
        this.operations = {}; // Object containing the handlers for promises created when tasks are requested
        this.id = id; // locally store id
        this.connection = null;
        this.sending = false;
        this.topics = topics;
        this.subscriptions = {};
        this.container = amqp.create_container(); //Maybe add an id?

        // Initializes stuff
        this.Initialization();
    }

    /**
     * @param {Object} connDetails The object provided in the constructor 
     */
    Initialization(){

        // Check for open connection to setup consumers
        this.container.on('connection_open', context => {
            const address = `${this.listener}/task`;
            const taskRcvr = this.connection.open_receiver({
                source: {address},
                autoaccept: false
            }); // Listener for task requests

            Object.entries(this.topics).forEach( ([topic, handler]) => {
                this.subscribe(topic, handler);
            });

            // Listening for task requests - must ensure the task is run, the 
            taskRcvr.on("message", async context => {
                if(this.connection){
                    const { message, delivery } = context;
                    const now = new Date();
                    const { body, reply_to, correlation_id, absolute_expiry_time } = message;
                    const { task } = body;
                    const response = { // Generic response object
                        to: reply_to, // Not sure if it is necessary
                        correlation_id // Necessary to ensure the reply gets processed properly
                    }

                    if(task){ // If you send a message to this listener, it must include a task
                        if(task in this.tasks){ // Check if task exists in tasks object
                            try{
                                response.body = { // Proper body assuming everything works right
                                    code: 1,
                                    result: await this.tasks[task](this, body)
                                };
                                delivery.accept();
                            }
                            catch(error){ // Catching an error encountered during the task
                                response.body = {code: -3, message: `Task runtime error ${error}`}
                                delivery.reject();
                            }
                        }
                        else{ // Sending back an error in case the task is not supported
                            response.body = {code: -2, message: `Unsupported task '${task}'`}
                            delivery.reject();
                        }
                    }
                    else{ // Sending back an error if a task name is not supplied
                        response.body = {code: -1, message: `No task requested`};
                        delivery.reject();
                    }

                    //Check if timeout occurred
                    if(absolute_expiry_time > now.getTime()){
                        const sender = this.connection.open_sender(reply_to); // Creating a sender to send the result back to the requester
                        // Recommended way of sending a message, the sender must acquire
                        sender.once("sendable", context => {
                            const another = new Date();
                            console.log("SENDING BACK", absolute_expiry_time - another.getTime());
                            sender.send(response); //Actually sending the message
                            sender.close();
                        });
                    }
                }

            });

            this.emit("connected");
        });

        // Listening for a connection error - emiting its own error event, must improve in the future
        this.container.on('connection_error', (err) => {
            this.emit('error', err);
        });

        // Listening for a protocol error - emiting its own error event, must improve in the future
        this.container.on("protocol_error", (err) => {
            this.emit('error', err);

        });

        this.container.on("disconnected", (context) => {
            this.emit('disconnected', context);
        });

        // Listening for a generic error - emiting its own error event, must improve in the future
        this.container.on("error", (err) => {
            this.emit('error', err);
        });
    }

    /**
     * @param  {Object, str} target Queue/topic to send a message to
     * @param  {Object} message An object containing the message to be send - if a task is required to be preformed that key must be sent
     * @param  {Object} extra customize sending defaults
     * @return {Promise} Awaitable promise to recieve the result of the request
     */
    send(address, message, extra = {}){
        return new Promise((resolve, reject) => { // Do I return the promise? I would want to await it
            const correlation_id = nanoid();
            const {timeout, broadcast} = extra;
            const to_val = timeout ? timeout : 5000;
            const now = new Date();

            if(this.connection){
                const sender = this.connection.open_sender({
                    target: { address },
                    autoaccept: false
                });

                sender.once("sendable", (context) => {
                    // Listen to the result for this task
                    //const resultRcvr = this.connection.open_receiver(reply_to);
                    const resultRcvr = this.connection.open_receiver({
                        source: {
                            dynamic: true,
                            dynamic_node_properties: "delete-on-no-messages"
                        },
                    });

                    // Listening for task results
                    // and check to resolve/reject the underlying task promise
                    resultRcvr.on("message", context => {
                        const { message } = context;
                        const { body, correlation_id } = message;
                        const { code } = body;

                        // Check if the operation is underway on this instance
                        if(this.operations[correlation_id]){
                            if(code > 0){ // Result codes larger than 0 is a success
                                this.operations[correlation_id].resolve(body);
                            }
                            else{ // Result code smaller than or equal to 0 represent a failure
                                this.operations[correlation_id].reject(body);
                            }
                            clearTimeout(this.operations[correlation_id].timeout); // Clears timeout function
                            delete this.operations[correlation_id]; // Regardless of result operation is finished
                        }
                        this.sending = false;
                        resultRcvr.close();
                    });

                    // Do I want to check this message object?
                    resultRcvr.on("receiver_open", (context) => {

                        const reply_to = context.receiver.source.address;
                        const sent_time = new Date();
                        //resultRcvr.credit()

                        const sent = sender.send({
                            reply_to,
                            body: message,
                            correlation_id,
                            ttl: to_val,
                            absolute_expiry_time: now.getTime() + to_val
                        });

                        if(broadcast){
                            resolve(sent);
                            resultRcvr.close();
                            this.sending = false;
                        }
                        else{
                            // Setting a timeout for the sending of a function in case it does not get properly handled
                            const t_o = setTimeout(
                                () => {
                                    if(this.operations[correlation_id]){
                                        this.operations[correlation_id].reject({code: -4, message: `Request timed out`});
                                        delete this.operations[correlation_id]
                                    }
                                    this.sending = false;
                                    resultRcvr.close();
                                },
                                to_val
                            );
                            // When sending a request must set a unique operation id with the functions to resolve or reject the underlying promise
                            this.operations[correlation_id] = {resolve, reject, timeout: t_o};
                        }
                        this.sending = true;
                        sender.close();
                    });
                });
            }
        });

    }

    /**
     * Publishes data to a topic, does not wait for a reply - TOPIC =/= QUEUE
     * @param  {string} address The topic name to send the message to
     * @param  {Object} message The message to be sent, 
     * @param  {Object} extra   Extra parameters
     * @return {undefined}         undefined
     */
    publish(address, message, extra = {}){
        const topic = `topic://${address}`;

        if(this.connection){
            const sender = this.connection.open_sender({
                target: { address: topic }
            });

            sender.once("sendable", (context) => {
                const sent = sender.send({
                    body: message
                });
                sender.close();
            });
        }
    }

    /**
     * Subscribed to a topic. 
     * @param  {string} topic   Topic string to be subscribed to
     * @param  {function} handler Handler function to be called when a message from that topic is received
     * @return {bool}         Returns true if Subscription was successful, false if a problem ocurred
     */
    subscribe(topic, handler){

        const source = {address:`topic://${topic}`};

        if(topic in this.subscriptions){
            this.emit('error', {reason: "Subscription already exists"});
            return false;
        }

        this.subscriptions[topic] = this.connection.open_receiver({source});

        this.subscriptions[topic].on("message", async context => {
            const { message, delivery } = context;

            handler(this, message);
        });

        return true;
    }

    /**
     * Ubsubcribess from a subscribed topic. 
     * @param  {string} topic   Topic string to unsubscribe from
     * @return {bool}         Returns true if Subscription was successful, false if a problem ocurred
     */
    unsubscribe(topic){
        if(!(topic in this.subscriptions)){
            this.emit('error', {reason: `Not subscribed to topic '${topic}'`});
            return false;
        }

        this.subscriptions[topic].close();
        delete this.subscriptions[topic];

        return true;
    }

    /**
     * Starts the connection setup in the initialization
     * @return {rhea.Connection} Instance of the established connection
     */
    connect(connDetails){
        // Instantiating the connection object 
        if(!this.connection){
            this.connDetails = connDetails;
            this.connection = this.container.connect(this.connDetails);
        }
        return this.connection;
    }

    /**
     * Closing function to stop listening to requests
     * @return {bool}
     */
    close(){
        if(this.connection){
            console.log("CLOSEING", this.connection);
            this.connection.close();
            this.connection = null;
        }
        this.emit("closed");
    }

}

module.exports = Comms;
import React from 'react';
import { Container, Card, CardBody, Row, Col } from 'shards-react';
import Graph from '../components/Graph/HighchartOverview';
import BatteryPowerGraph from '../components/Graph/BatteryPowerChart';
import BatterySOCGraph from '../components/Graph/BatterySoCChart';
import BatteryVoltageGraph from '../components/Graph/BatteryVoltageChart';
import TrialChart from '../components/Graph/TrialChart';
import BMS2 from '../components/Graph/BMS2';

const Chart = (props) => {

 // console.log('Link data '+props.location.state.batid)

  return (
  <Container fluid className='main-content-container py-4'>
    <Row>
      {/* Charts */}
    
      <Col lg='12' md='30' sm='20' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
          <TrialChart/>
          </CardBody>
        </Card>
      </Col>

    
    </Row>
  </Container>
)
}

export default Chart;


{
/*
  <TrialChart batid={props.location.state.batid} sysid={props.location.state.sysid}/>
           
      <Col lg='6' md='12' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
          <BMS2/>
          </CardBody>
        </Card>
      </Col>
  <Col lg='6' md='12' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
            <BatteryVoltageGraph/>
          </CardBody>
        </Card>
      </Col>
      <Col lg='6' md='12' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
            <BatteryPowerGraph />
          </CardBody>
        </Card>
      </Col>
      <Col lg='6' md='12' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
            <BatterySOCGraph />
          </CardBody>
        </Card>
      </Col> 
*/

}
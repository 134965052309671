import React from 'react';
import { Container, Card, CardBody, Row, Col } from 'shards-react';
import GridPowerGraph from '../components/Graph/GridPowerGraph';
import GridCurrentGraph from '../components/Graph/GridCurrentGraph';
import GridFreqGraph from '../components/Graph/GridFreqGraph';
import TrialChart from '../components/Graph/TrialChart';

const Chart = () => (
  <Container fluid className='main-content-container py-4'>
    <Row>
      {/* Charts */}

      <Col lg='6' md='12' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
            <TrialChart/>
          </CardBody>
        </Card>
      </Col>
      <Col lg='6' md='12' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
            <GridCurrentGraph/>
          </CardBody>
        </Card>
      </Col>
      <Col lg='6' md='12' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
            <GridFreqGraph />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Chart;

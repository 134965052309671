import React from 'react';

import ReactStoreIndicator from 'react-score-indicator'

const axios = require('axios');
const DATA_UPDATE_RATE_MS = 500;


class GridFreqGraph extends React.Component {
  constructor(props) {
    //const { state } = props.location;
    super(props);
    this.state = {
      // graphTitle: state && state.data ? state.data : null,
      // graphType: state && state.type ? state.type : null,
      seriesData: [],
      freqValue : 0,
      batPower :0,
      acPower :0
    };
  }

  getData = () => {
      fetch('https://9w6ur1v40h.execute-api.eu-west-1.amazonaws.com/dev/SystemData')
      .then(res => res.json())
      .then(data => {

        let freq=data.ACFreq;
        let BatteryPower=data.BatteryPower;
        this.setState({
          freqValue: freq+"Hz",
          batPower : BatteryPower/1000 +"kW"
        });
      });
  }
   
  componentDidMount() {
    this.updateTimer = setInterval(async () => {
    this.getData();
  }, DATA_UPDATE_RATE_MS);
  } 


  render() {
    const { seriesData,freqValue,batPower } = this.state;
    
    return (
        <div>
        <ReactStoreIndicator
          value={freqValue} />
      
        <ReactStoreIndicator
          value={batPower}
        />
      </div>
    );
  }
}

export default GridFreqGraph;




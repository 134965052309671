import React, { useEffect,useState,useContext} from 'react';
import { Container, Card, CardBody, Row, Col } from 'shards-react';
import { Link, withRouter } from 'react-router-dom';
import { Repeat } from 'react-floco';
import '../components/EnergyFlow/EnergyFlow.css';
import Dashboard from '../pages/Dashboard'
import './SystemDashboard.css';

import { UserContext  } from "../libs/contextLib";

function SystemDashboard ()
{
  const System = useContext(UserContext);
  const [systemArray,setsystemArray] = useState(null);

  useEffect(()=>{
    getSystems();
  },[])

  async function getSystems(){

    let token = localStorage.getItem('user_id');
    const res= await fetch(
    'https://9w6ur1v40h.execute-api.eu-west-1.amazonaws.com/dev/getSystems',
    {
      headers:
      {
        Authorization: `Bearer ${token}`
      }
    });
    const data=await res.json()

    setsystemArray(data.Items)
  }

  return (
    <div className="App">
    <h1>Lifepowr IO Systems</h1>
    <h2></h2>


    <Col>
    <Row>
    <div class="module">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle class="stroke-2" cx="60" cy="60" r="50"/>
            <text x="20%" y="40%" text-anchor="middle" stroke="black"  stroke-width="1px" dy=".3em">Systems : {systemArray && systemArray.length}</text>
        </svg>
    </div>
    </Row>
    </Col>

    <div className="systems">
        {systemArray &&
          systemArray.map((system,index) => {
          return (

              <div className="system" key={index}>

                <h3>System {index + 1}</h3>
                <h2>{system.System_name}</h2>
                <Link to={{
                   pathname: '/Dashboard',
                   state: { systemid: system.SystemID , systemname: system.SystemID }
                }}>
                <div className="details"
                value={system.System_name}
                onClick ={function (event) {
                                             System.setSystem_name(system.System_name)
                                             System.setSystem_id(system.SystemID)
                                            } } >

                  <p>🏘️: {system.System_name}</p>
                  <p>📍: Antwerp </p>
                          </div>
                </Link>
              </div>

            );
          })}
      </div>




    </div>


  );


}
/*
const getSystemImage = (sys_lable) => {
  return (
    <div class='batteryDiv'>
      <div class='verticalsys'>
         <span class='systemLable'> {sys_lable}</span>
       </div>
    </div>
  );
};

    <Container fluid className='main-content-container py-4'>
    <Row>
    <h2>Systems </h2>

    <br></br>
    <Col lg='6' md='10' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
          <input type="text" className="input" placeholder="Search..." />
          {systemArray && systemArray.map(item => (

              <Link
                to={{
                   pathname: '/Dashboard',
                   state: { systemid: item.SystemID , systemname: item.SystemID }
                }}>
                {getSystemImage(item.SystemID)}
              </Link>

          ))}

          </CardBody>
        </Card>
    </Col>
    </Row>

    </Container>
*/


/*

   <Col lg='6' md='10' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
          {systemArray && systemArray.map(item => (

              <Link
                to={{
                   pathname: '/Dashboard',
                   state: { systemid: item.SystemID , systemname: item.SystemID }
                }}>
                {getSystemImage(item.SystemID)}
              </Link>

          ))}

          </CardBody>
        </Card>
    </Col>


  <Row>
    <Col lg='6' md='10' sm='12' className='mb-4'>
     <div class="module">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle class="stroke-2" cx="60" cy="60" r="50"/>
            <text x="50%" y="50%" text-anchor="middle" stroke="#51c5cf" stroke-width="2px" dy=".3em">3,0 MW</text>
            </svg>
          </div>
          <h6> Total Consumption </h6>

    </Col>
    <Col lg='6' md='10' sm='12' className='mb-4'>
          <div class="module">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle class="stroke-2" cx="60" cy="60" r="50"/>
            <text x="50%" y="50%" text-anchor="middle" stroke="#51c5cf" stroke-width="2px" dy=".3em">3,0 MW</text>
            </svg>
          </div>
          <h6> Total Consumption </h6>
    </Col>
  </Row>


const SystemDashboard = () => (

  <Container fluid className='main-content-container py-4'>
    <Row>
    <Col lg='6' md='10' sm='12' className='mb-4'>
        <Card small className='h-100'>
          <CardBody className='pt-0'>
          <Link to='Dashboard'>Click</Link>
          <Repeat times={3}>
            { ({ key }) => <p key={key}>Are we there yet?
            {getSystemImage(50)}
            </p> }
          </Repeat>

          </CardBody>
        </Card>
    </Col>
    </Row>
    </Container>
); */

export default SystemDashboard;

